.text-block {
  font-weight: 400;
}

.footer-black {
  background-color: black;
  align-items: center;
}

.cursor-hand {
  cursor: pointer;
}

.active {
    color: #00adee !important;
}

.section-dark {
    background-color: #00adee;
    color: white;
}

.implode {
    display: inline;
    padding: 10px 30px;
}

.btn2 {
    display: inline-block;
    box-sizing: border-box;
    border-width: 2px;
    font-size: 18px;
    font-weight: 300;
    padding: 0.5rem 18px;
    line-height: 1.75;
    // cursor: pointer;
    // text-transform: uppercase;
    // background-color: #66615B;
    // border-color: #66615B;
    color: #FFFFFF;
    opacity: 1;
    // filter: alpha(opacity=100);
    // transition: all 150ms linear;
}

.fit-image{
    margin-top: 32px;
    width: 100%;
    object-fit: scale-down;
    // height: 300px; /* only if you want fixed height */
    vertical-align: middle;
}